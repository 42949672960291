module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/jasmin-y-pedro/jasmin-y-pedro/node_modules/gatsby-remark-images","id":"49e80d0f-d1be-5e32-9548-6b19d0418c3e","name":"gatsby-remark-images","version":"6.23.1","modulePath":"/home/runner/work/jasmin-y-pedro/jasmin-y-pedro/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":"900000000000","linkImagesToOriginal":false,"backgroundColor":"none"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/jasmin-y-pedro/jasmin-y-pedro","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":"900000000000","linkImagesToOriginal":false,"backgroundColor":"none"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
